import { initCounter } from './assets/js/counter.js';


window.addEventListener('load', function() {
  initCounter();

    const form = document.getElementById('form-contact-sales');
  form.addEventListener('submit', function(event) {
    event.preventDefault(); // Prevents the form from submitting

    grecaptcha.enterprise.ready(async () => {
      const token = await grecaptcha.enterprise.execute('6Lf-zIAqAAAAAFA4jdCsHLyMYtrESMiTPB3INIkK', { action: 'SALES_FORM' });
      // contact-sales-name
      const name = document.getElementById('contact-sales-name').value;
      const text = document.getElementById('contact-sales-text').value;

      const headers = new Headers();
      headers.append('Content-Type', 'application/json');

      const raw = JSON.stringify({
        'name': name,
        'subject': 'Request for sale',
        'text': text,
        'captcha_token': token,
        'contact_email': 'ifdotpy@gmail.com',
      });

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: raw,
        redirect: 'follow',
      };

      fetch('https://n8n.hplc.cloud/webhook/support-email', requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.error(error));
    });
  });
});



// document.addEventListener('DOMContentLoaded', function() {
//   const contactForm = document.getElementById('form-contact-sales');
//   const contactModal = document.getElementById('bs-modal-contact-sales');
//
//   contactForm.addEventListener('formSubmitSuccess', function() {
//     const bsModal = Modal.getInstance(contactModal);
//     bsModal.toggle();
//   });
//
//   contactForm.addEventListener('formSubmitFailed', function() {
//     SEMICOLON.Modules.notifications(document.querySelector('#form-contact-sales-error-message'));
//   });
// });